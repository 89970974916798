<template>
  <v-card flat color="celeste" class="rounded-0">
    <v-container>
      <v-row>
        <v-col >
          <template v-if="$vuetify.breakpoint.mobile">
            <span class="d-block headline pt-12 ">PARTICIPAR A LA PRIMERA VENTA DE UN INMUEBLE 100% MEDIANTE BLOCKCHAIN</span>
            <inscribite-button no-block white class="mt-5 mb-12" />
          </template>
          <template v-if="!$vuetify.breakpoint.mobile">
            <span class="d-block display-1 pt-12 ">PARTICIPAR A LA PRIMERA VENTA</span>
            <span class="d-block display-1 pb-4 ">DE UN INMUEBLE 100% MEDIANTE BLOCKCHAIN</span>
            <inscribite-button no-block white class="mb-12" />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'InscribiteSection',
  components: {
    InscribiteButton: () => import('@/views/components/InscribiteButton')
  }
}
</script>

<style>

</style>